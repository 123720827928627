.Data {
  display: "flex";
  flex-direction: "column";
  align-items: 'flex-start';
}

.Data h1 {
  padding: 20px;
  background-color: #111;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 25px;
  text-align: left;
}
.Light .Data h1 span{
  color : #222;
  animation: none;
}
.Data h1 span{
  color : cyan;
}
.Light .Data h1 , .Data h1#text {
 background-color: teal;
 color: white;
}