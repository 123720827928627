@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");

.flex {
  display: flex;
  align-items: center;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Cairo", "sans-serif" !important;
}

/* p{
  font-size: 10px;
 } */

main {
  color: #1b1b1b;
  min-height: 90vh;
  font-size: 30px;
  font-weight: bold;
  line-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* padding-top: 80px; */
}

* {
  margin: 0;
  padding: 0;
  color: white;
  font-family: sans-serif;
  text-decoration: none;
  list-style: none;
}

html {
  box-sizing: border-box;
  background-color: #f2f2f2;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

header {
  background-color: #1b1b1b;
}

h1 {
  padding: 20px;
  font-size: 25px;
}

/* ==========================
Level2 (Sign-in - Sign-up)
=============================*/
.error {
  color: red;
  font-size: 15px;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 18px;
  padding: 6px 4px;
  border: 1px solid #607d8b;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
}

form button {
  padding: 8px 12px;
  background-color: teal;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  border: none;
  color: white;
  cursor: pointer;
}

.account {
  font-size: 16px;
  margin-top: 12px;
  font-weight: 100;
}

.account a {
  text-decoration: underline;
  font-weight: 600;
  color: #00c6c6;
  font-size: 18px;
}

.Light form p {
  color: rgb(0, 0, 0);
}

.Light main p {
  color: #333;
}

form button:active {
  scale: 0.9;
  border-radius: 0;
}

.Light main h1 {
  color: #444;
}

/* span animation */
span {
  animation: heart 2s infinite linear;
}

@keyframes heart {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.logo {
  animation: logoani 5s infinite;
  transition: all 0.5s;
}

@keyframes logoani {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes modal {
  0% {
    transform: translate(0, -100vh);
    scale: 0;
  }

  100% {
    transform: translate(0, 0);
    scale: 1;
  }
}

/* ==========================
   for all buttons
  =========================== */
button:hover {
  opacity: 0.93;
}

button:active {
  scale: 0.95;
}

/* ==========================
   for scroll bar
  =========================== */
::-webkit-scrollbar {
  width: 1.1rem;
}

::-webkit-scrollbar-track {
  border: 7px solid #232943;
  box-shadow: inset 0 0 2.5px 2px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #150f12, #3d4a59);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(270deg, #4a4748, #3d4a59, #1a191a);
}

/* for Firefox */
html {
  scrollbar-color: #9e4673 #3d4a59;
}
.Light .para time{
  color: #1c8a8a !important;
}
.Light .Data h1 span{
  color: #000 in !important;
}