.theme-btn {
  outline: 0;
  border-radius: 1rem;
  padding: .3rem .5rem;
  color: #fff;
  /* background-color: #3d4a59; */
  background-color: transparent;
  border: 1.5px solid #ddd;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all .3s;
}

.theme-btn:hover {
  box-shadow: 0 0 10px #555, 0 0 40px #555, 0 0 80px #555;
  background: #555;
  border: none;
  outline: 0;
}

.theme-btn:focus {
  padding: 4px 12px;
  box-shadow: 0 0 10px #555, 0 0 40px #555, 0 0 80px #555;
  box-shadow: 0 0 0 5px #3d4a59, 0 0 0 6px #fff;
}

/* ==============
  Dark mode
=================*/

.Dark {
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;
}

.Dark main {
  color: rgba(255, 255, 255, 0.87);
}

.Dark .ali {
  background-color: rgba(17, 17, 17, 0.885) !important
}


/* ==============
Icon (sun & moon)
==================*/
.fa-solid {
  font-size: 20px;
}

.fa-solid:hover {
  font-size: 22px;
  cursor: pointer;
  transition: all 0.1s;
}
.Light .fa-sun {
  display: none;
}

.fa-sun{
  color: orange;
}

.Dark .fa-moon {
  display: none;
}