/* =================
 table of contents
===================== 
1-  OPIONS (filtered data)
2- SHOW all tasks
3- Add new task BTN*/








/* ==============================
1-  OPIONS (filtered data)
=================================*/
.home {
  justify-content: flex-start;
}

.home section {
  margin-bottom: 30px;
}

.home .parent-of-btns {
  margin-top: 30px;
  position: relative;
}

.home .parent-of-btns button {
  background-color: #3467c2;
  font-size: 16px;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}

.home .parent-of-btns button:active {
  scale: .95;
}

.home .parent-of-btns select:focus {
  outline: none;
}

.home select {
  background-color: #666;
  padding: 7px 14px;
  border-radius: 4px;
}

.home .parent-of-btns .orderbtn {
  opacity: .4;
}

.home .parent-of-btns .inActive {
  opacity: 1;
}

/* ==============================
2- SHOW all tasks
=================================*/
.home .all-tasks {

  justify-content: center;
  flex-wrap: wrap;

}


.home .all-tasks .one-task {
  border-bottom: 1px solid teal;
  border-left: 1px solid teal;
  --tw-shadow: 0px 1px 3px #0000003d, 0px 2px 8px #15c0e229;
  --tw-shadow-colored: 0px 1px 3px var(--tw-shadow-color), 0px 2px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin: 40px 20px;
  min-width: 300px;
  height: 180px;
  border-radius: 10px;
  transition: 0.2s;
  position: relative;
}


/* .home .all-tasks .one-task:hover {
  scale: 0.99;
} */

.home .all-tasks .one-task h2 {
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.home .all-tasks .one-task li {
  list-style-position: inside;
  list-style-type: circle;
  line-height: 29px;
  font-size: 13px;
  /* WRONG */
  text-align: start;
  margin: 0 11px;
}

.home .all-tasks .one-task time {
  font-size: 10px;
  color: rgb(164, 213, 255);
  /* margin-top: 8px; */
  position: absolute;
  top: -40px;
  left: 10px;
}

.home .all-tasks .one-task .data_parent {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

/* ==============================
3- Add new task BTN
=================================*/

.add-task-btn {
  background-color: teal;
  font-size: 16px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.home-add-task-btn {
  width: 300px;
  margin-right: 10px !important;
}

.add-task-btn:active {
  scale: .95;
}

.home .add-task-btn .fa-plus {
  font-size: 14px;
}

/* light mode  */
.Light .all-tasks .one-task {
  background-color: #444;

}

.Light .home .all-tasks .one-task:hover {
  border-left: 8px solid teal;
}

/* end */


/* ==============================
4- Add new task BTN
=================================*/


.sub_tasks .tasks {
  color: black;
  font-size: 14px;
  line-height: 30px;
  list-style-type: circle;
  margin-left: 18px;
}

.send-email {
  margin-top: 20px;
  background-color: teal !important;
}

p i.fa-solid .fa-heart {
  color: red !important;
  cursor: alias;
}

/* task_options */


.task_options i {
  font-size: 22px;
}

.task_options i:hover {
  font-size: 21px;
}

.task_optionsi:hover {
  opacity: 0.9;
  padding: 6px;
}

.task_options i:active {
  scale: .9;
}

.task_options i {
  margin-left: 15px;
}

.task_options i {
  color: whitesmoke;
}

.parent_options {
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 10px;
  position: absolute;
  bottom: 0;
  right: 0;

}

.completed-icon {
  position: absolute;
  top: 0;
  right: 0;
}

p.start-timer {
  background-color: #888;
  color: #222;
  font-size: 15px;
  width: 100px;
  line-height: 30px;
  border-radius: 8px;
  position: absolute;
  display: none;

}

.icons_parent {
  position: absolute;
  bottom: -1px;
  left: 15px;
}

.timer:hover {
  font-size: 24px !important;
}

.icons_parent i {
  font-size: 25px;
}

.pos-timer {
  bottom: -35px;
  left: -20px;
}
.pos-done{
  bottom: -35px;
  left: 230px;
}
.pos-trash{
  bottom: -35px;
  left: 190px;
}
.pos-info{
  bottom: -35px;
  left: 160px;
  display: none;
}
.info:hover + .pos-info{
display: block !important;
}
.trash:hover + .pos-trash{
display: block !important;
}
.check-mark:hover + .pos-done{
display: block !important;
}
.timer:hover + .pos-timer{
display: block !important;
}
.timer-box{
  display: block !important;
  font-size: 25px !important;
  width: 150px !important;
  color: #222 !important;
  bottom: 8px;
  left: -7px;
}
.repeat{
  font-size: 20px !important;
  margin-left: 10px;
}
i:active{
  scale: 0.98;
}