
.myheader .ali{
  background-color: teal;

}

.main-list {
  padding: 10px;
  font-size: 19px;
  position: relative;
}

.main-link {
  padding: 6px;
}
/* ===============================================
=========      languages menu ====================
=============================================== */

.drop
{
  font-size: 15px !important;
}
.lang{
  /* border: 1px solid red; */
  height: 65px;
  padding-top: 15px;
}
.lang:hover ul.languages{
  display: block;
}
ul.languages
{
  display: none;
  position: absolute;
  z-index: 4;
  border-top: 1px solid cyan;
  border-bottom: 1px solid cyan;
  top: 63px;
  left: -3px;
  border-radius: 5px;
}
ul.languages .lang-li
{
  background-color:#222;
  padding: 10px 5px;
  width: 130px;
  border-bottom: 1px solid #111;
  font-size: 15px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  
}
ul.languages li:hover{
  font-size: 15.2px;
  cursor: pointer;
  background-color: #181818;
}
.last-lang
{
  /* border-radius:0 0 5px 0; */
  border-bottom: none !important;
}
/* ==================================================== */
.main-link:hover,  .main-link.active{
  color: cyan;
  box-shadow: 0 0 5px cyan;
  border-radius: 5px;
}


.hide-when-mobile {
  display: flex;
  justify-content: space-around;
  align-items: center
}

/* ==================
    3. header of mobile
===================== */
.show-when-mobile {
  display: none;
}

@keyframes down {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0);
  }
}

header {
  animation: down 1s 1;
}
















