.myfooter footer{
  background-color: #1b1b1b;
  padding: 20px 0;
  text-align: center;
  font-size: 18px;
}



.myfooter .ali{
  background-color: teal;
}
.Omar{
  border-bottom: 1px solid cyan;
  color : cyan;
  animation: none;
}

 


